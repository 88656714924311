import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import footerStyles from "./footer.module.scss"
import GatsbyLogo from "../images/gatsby_logo.png"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <footer className={footerStyles.footer}>
      <p>
        Created by {data.site.siteMetadata.author},{" "}
        <img src={GatsbyLogo} alt="Gatsby logo" /> © 2019-2020
      </p>
    </footer>
  )
}

export default Footer
